// Common styles
body
  background: $neutrals8
  font-size: 18px
  line-height: 1.5
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $neutrals1
  box-sizing: border-box
  scroll-behavior: smooth
  +dark-body
      background: $neutrals1
      color: $neutrals8

a
  text-decoration: none

svg,
img
  vertical-align: middle

.desktop
  &-hide
    +d
      display: none !important
  &-show
    display: none !important
    +d
        display: block !important
  &-text-right
    +d
        text-align: right

.tablet
  &-hide
    +t
        display: none !important
  &-show
    display: none !important
    +t
        display: block !important

.mobile
  &-hide
    +m
        display: none !important
  &-show
    display: none !important
    +m
        display: block !important

*, *:before, *:after
  box-sizing: inherit

body, h1, h2, h3, h4, h5, h6, p, ol, ul, div
  margin: 0
  padding: 0

ol, ul
  list-style: none

img
  max-width: 100%
  height: auto

a
  text-decoration: none
  color: #5656ea

::selection
  

@font-face 
  font-family: 'Tomato Grotesk'
  src: url('../../public/fonts/TomatoGrotesk-Semibold.eot')
  src: url('../../public/fonts/TomatoGrotesk-Semibold.eot?#iefix') format('embedded-opentype'), url('../../public/fonts/TomatoGrotesk-Semibold.woff2') format('woff2'), url('../../public/fonts/TomatoGrotesk-Semibold.woff') format('woff')
  font-weight: 600
  font-style: normal
  font-display: swap