[class^="section"]
    padding: 120px 0
    +d
        padding: 112px 0
    +m
        padding: 64px 0

.section-bg
    background: $neutrals7 
    +dark-common
        background: $neutrals2

.section-border-top
    border-top: 1px solid $neutrals6
    +dark-common
        border-color: $neutrals3

.section-border-bottom
    border-bottom: 1px solid $neutrals6
    +dark-common
        border-color: $neutrals3

.section-pb
    padding-top: 0
    +d
        padding-top: 0
    +m
        padding-top: 0

.section-pb64
    padding: 0 0 64px
    +d
        padding: 0 0 64px