[class^="status"]
  display: inline-block
  padding: 0 8px
  border-radius: 4px
  font-size: 12px
  line-height: 26px
  font-weight: 700
  text-transform: uppercase
  color: $neutrals8

.status-blue
  background: $blue

.status-red
  background: $red

.status-green
  background: $green

.status-pink
  background: $pink

.status-black
  background: $neutrals2
  +dark-common
    background: $neutrals8
    color: $neutrals2

.status-purple
  background: $purple

.status-white
  background: $neutrals8
  color: $neutrals2

.status-stroke-red
  box-shadow: inset 0 0 0 2px $red
  color: $red

.status-stroke-green
  box-shadow: inset 0 0 0 2px $green
  color: $green

.status-stroke-pink
  box-shadow: inset 0 0 0 2px $pink
  color: $pink

.status-stroke-black
  box-shadow: inset 0 0 0 2px $neutrals2
  color: $neutrals2
  +dark-common
    box-shadow: inset 0 0 0 2px $neutrals8
    color: $neutrals8

.status-stroke-purple
  box-shadow: inset 0 0 0 2px $purple
  color: $purple

.status-grey
  background-color: $neutrals5
  color: $neutrals8




